import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "./index.scss"
const IndexPage = () => (
  <Layout>
    <main className="index-main">
    <SEO title="Consulenza Digital Marketing e sviluppo di siti Web" />
    <h1 className="main-title">Eugenio Pilastro Luca</h1>
    <div className="image-container">
      <Image />
    </div>
    <div className="text-container">
    <h2 className="main-title">Consulente Digital Marketing</h2>
    <p className="index-subtitle">Aiuto aziende e professionisti a crescere utilizzando strumenti digitali</p>
    </div>
   <div className="button-container">
    <div className="button">
    <Link to="/cosa/">Come?</Link>
    </div>
    </div>
    </main>
  </Layout>
)

export default IndexPage
